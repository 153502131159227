import { useSnackbar } from "notistack";
export default function useCustomSnackbar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, type = "error", lessWidth = false) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: type !== "error" ? "default" : type,
      id: lessWidth == false ? "" : "failure",
      autoHideDuration: 2500,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      // , persist:true
    });
    console.log("Enqueue Snackbar Called");
  };

  return snackbar;
}
