import { useEffect } from "react";
import NewDiscount from "../components/new_discount/NewDiscount";
// import StorewideSaleState from "../context/storewide_sale/StorewideSaleState";

const Index = () => {
  console.log("Hello");
  return (
    <>
    
      <NewDiscount />

      <div style={{ display: "none" }}>
        <img src="/img/summary-logo.png" />
        <img src="/img/product_filtering_modal.png" />
        <img src="/img/review_modal_icon.png" />
        <img src="/img/upload_csv_modal_icon.png" />
        <img src="/img/save_bulk_price_modal.png" />
        <img src="/img/save_create_sale_modal.png" />
        <img src="/img/drag_sale_modal.png" />
      </div>
    </>
  );
};

export default Index;
