import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const ProductFetchModal = (props) => {
  const {
    productsFetchingModal,
    filterStatusCurrentCount,
    productsFetchingStatusCurrentCount,
    filterStatusTotalCount,
    secondNeeded,
    filteringStatusFinishingUp,
    filteringStatusCompleted2,
    setProductsFetchingModal,
    filterStatus,
  } = props;

  const classes = useStyles();

  const handleContinue = () => {
    setProductsFetchingModal(false);
  };

  //calculate estimate time
  let estimatedRemainingTime = "";
  let minute;
  let second;
  if (secondNeeded > 0) {
    minute = Math.floor(secondNeeded / 60);
    second = Math.ceil(secondNeeded % 60);
    if (minute > 0)
      estimatedRemainingTime =
        minute + " Minute(s) and ";
        estimatedRemainingTime += second + " Second(s)";
   }
  return (
    <div>
      <Modal
        open={productsFetchingModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        id="app-install-modal-container"
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <div className={`${classes.paper} app-install-modal-bg bg-img`}>
          <div className={classes.container}>
            <div className={classes.item}>
              <div>
                <img
                  className="logo-fetching-modal"
                  src="/img/app-logo.png"
                  alt="Logo"
                />
              </div>

              <div className={classes.modalTitle}>
                <div>Bevy Sales Rules</div>
              </div>
            </div>

            <div className={classes.bottomSection}>
              <div className={classes.modalSubTitle}>
                <div className={classes.modalCountNumber}>
                  {/* currently syncing */}
                  {productsFetchingStatusCurrentCount}
                </div>
                <div className={classes.modalCountText}>Synced Products</div>
              </div>

              <div className={classes.bottomSectionLogo}>
                <img
                  className={classes.syncPage}
                  src="/img/sync-page.png"
                  alt="Logo"
                />
              </div>

              <div>
                <div className={classes.modalCountNumber}>
                  {/* total products */}
                  {filterStatusTotalCount}
                </div>
                <div className={classes.modalCountText}>Total Products</div>
              </div>
            </div>

            <div className={classes.bottomItem}>
              <div className={classes.modalSubTitle}>
                {/* currently ongoing process message  */}
                <div>
                  {filteringStatusCompleted2 ? (
                    <>Syncing Completed</>
                  ) : filteringStatusFinishingUp ? (
                    <>Finishing Up</>
                  ) : (
                    <>Syncing Products</>
                  )}
                </div>
              </div>

              <div className={classes.modalBar}>
                {(productsFetchingStatusCurrentCount == 0 &&
                  filterStatus != "completed") ||
                (filterStatusTotalCount == 0 && filterStatus != "completed") ? (
                  <>
                    <StyledLinearProgress
                      style={{
                        height: "1.25rem",
                        borderRadius: "0.75rem",
                      }}
                      variant="determinate"
                      value={0}
                    />
                  </>
                ) : (
                  <>
                    <StyledLinearProgress
                      style={{
                        height: "1.25rem",
                        borderRadius: "0.75rem",
                      }}
                      variant="determinate"
                      value={
                        productsFetchingStatusCurrentCount >= filterStatusTotalCount &&
                        filterStatus == "completed"
                          ? 100
                          : (productsFetchingStatusCurrentCount /
                              filterStatusTotalCount) *
                            100
                      }
                    />
                  </>
                )}
              </div>

              <div className={classes.modalText}>
                {filteringStatusCompleted2 ? (
                  <>You can now start using app</>
                ) : filteringStatusFinishingUp || secondNeeded < 0 ? (
                  <>
                    Looks like we need a little more time to get things working
                  </>
                ) : (
                  <>
                    Please wait while Bevy Sales Rules syncs all your products
                    to the app!
                  </>
                )}
              </div>

              {filteringStatusCompleted2 ? (
                <>
                  <div className={classes.clock}>
                    <div className="row d-flex justify-content-center">
                      <div className="col-6 mt-2">
                        <button
                          type="button"
                          className="btn btn-primary btn-block save-bulk-btn"
                          onClick={handleContinue}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : filteringStatusFinishingUp ? (
                <>
                  <div className={classes.clock}>
                    If you think this is taking too much time then please reach
                    out to our support
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.clock}>
                    <span style={{ marginRight: "1rem" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 512 512"
                        version="1.1"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        height="25"
                        width="25"
                        fill="#081e57"
                      >
                        <path d="M347.216 301.211l-71.387-53.54V138.609c0-10.966-8.864-19.83-19.83-19.83-10.966 0-19.83 8.864-19.83 19.83v118.978c0 6.246 2.935 12.136 7.932 15.864l79.318 59.489a19.713 19.713 0 0011.878 3.966c6.048 0 11.997-2.717 15.884-7.952 6.585-8.746 4.8-21.179-3.965-27.743z"></path>
                        <path d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.833 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.066-216.341-216.341S136.725 39.659 256 39.659c119.295 0 216.341 97.066 216.341 216.341S375.275 472.341 256 472.341z"></path>
                      </svg>
                    </span>
                    Estimated time remaining:{" "}
                    <span className={classes.modalSeconds}>
                      {estimatedRemainingTime && secondNeeded >= 0
                        ? estimatedRemainingTime
                        : ""}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductFetchModal;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    // border: '2px solid black',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    height: "100%",
    margin: "-1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  item: {
    // paddingTop: "6rem",
    // paddingBottom: "3rem",
    textAlign: "center",
    // border: '2px solid black',
  },

  bottomItem: {
    paddingTop: "6rem",
    textAlign: "center",
    // border: '2px solid black',
  },

  modalTitle: {
    marginTop: "2rem",
    fontSize: "2.8rem",
    fontWeight: 700,
    color: "#081e57",
  },

  modalSubTitle: {
    color: "#081e57",
    fontSize: "2.8rem",
    fontWeight: 500,
    marginBottom: "-0.5rem",
  },

  modalText: {
    marginTop: "3rem",
    color: "#081e57",
    fontSize: "1.4rem",
    fontWeight: 400,
  },

  clock: {
    marginTop: "2rem",
    color: "#081e57",
    fontSize: "1.4rem",
    fontWeight: 400,
  },

  modalSeconds: {
    fontWeight: 700,
    color: "#4765f0",
  },

  modalCountNumber: {
    color: "#4765f0",
    fontSize: "3.4rem",
    fontWeight: 600,
  },

  modalCountText: {
    color: "#081e57",
    fontSize: "1.6rem",
    fontWeight: 500,
  },

  syncPage: {
    height: "27rem",
    width: "27rem",
  },

  modalProductSync: {
    fontSize: "2rem",
  },

  modalProductSyncColor: {
    color: "#003b79",
  },

  bottomSection: {
    marginTop: "4rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  bottomSectionLogo: {
    height: "25rem",
    width: "25rem",
    borderRadius: "50rem",
    marginRight: "5.5rem",
    marginLeft: "3.5rem",
  },
  modalBar: {
    marginTop: "3rem",
  },
}));

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#c8d7fc",
  },
  barColorPrimary: {
    backgroundColor: "#4765f0",
    background:
      "linear-gradient(0deg, #203eca, #2542ef 10%, #4b69f7 50%, #7190fe)",
  },
})(LinearProgress);
