// import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export const Skeltons = props => {
  return <Skeleton {...props} />;
};
export const DefaultWrapper = ({ children }) => {
  return <div style={{ height: "100%", width: "100%s" }}>{children}</div>;
};
export const AnalticsSkelton = () => {
  return (
    <div className="homepage-container mt-5 mb-5">
      <div
        className="homepage-card homepage-container-margin panel-shadow-border col"
        style={{
          height: "95px",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 20
        }}
      >
        <h2 className="title customSkelton">
          <strong>{/* <Skeleton /> */}</strong>
        </h2>
        <h3 className="text-muted customSkelton">{/* <Skeleton /> */}</h3>
      </div>
      <div
        className="homepage-card homepage-container-margin  panel-shadow-border col"
        style={{
          height: "95px",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 20
        }}
      >
        <h2 className="title customSkelton">
          <strong>{/* <Skeleton /> */}</strong>
        </h2>
        <h3 className="text-muted customSkelton">{/* <Skeleton /> */}</h3>
      </div>
      <div
        className="homepage-card  panel-shadow-border col"
        style={{
          height: "95px",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 20
        }}
      >
        <h2 className="title customSkelton">
          {/* <strong>
            <Skeleton />
          </strong> */}
        </h2>
        <h3 className="text-muted customSkelton">{/* <Skeleton /> */}</h3>
      </div>
    </div>
  );
};
export const CustomSkelton = () => {
  return (
    <div style={{ height: "4rem", width: "15rem" }}>
      <div className="customSkelton" style={{ borderRadius: "40px" }}></div>
    </div>
  );
};

export const DefaultSkelton = CustomSkelton;

export const PickOptionSkelton = () => {
  return (
    <div className="homepage-container mt-5 pb-5">
      <div className="homepage-card bottom-container homepage-container-margin panel-shadow-border customSkelton">
        <CustomSkelton />
      </div>
      <div className="homepage-card bottom-container panel-shadow-border customSkelton">
        <CustomSkelton />
      </div>
    </div>
  );
};
