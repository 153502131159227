import { useEffect, useContext, useState, useCallback } from "react";
import Link from "next/link";
import { Modal } from "@shopify/polaris";
import Wrapper from "../layout/Wrapper";
import DiscountContext from "../../context/discount/DiscountContext";
import { useRouter } from "next/router";
import ProductFetchModal from "./ProductFetchModal";
import useCustomSnackbar from "../../hooks/useCustomSnackBar";
import {
  Skeltons,
  AnalticsSkelton,
  PickOptionSkelton,
  DefaultSkelton,
} from "./Skeltons/Skeltons";
import { authenticatedFetch } from "../../utils/authenticatedFetch.js";
import AppContext from "../../context/appContext";

const NewDiscount = () => {
  const discountContext = useContext(DiscountContext);
  const {host, jwtToken, shop1}=useContext(AppContext);
  const snackbar = useCustomSnackbar();

  const {
    getProductsFetchingStatus,
    productsFetchingStatus,
    productsFetchingStatusCurrentCount,
    productsFetchingStatusTotalCount,
    gettingFilterStatusFromRes,
    getBulkPriceChangeLog,
    shop,
    setSubscribtionStatus,
    handleAPIError,
    getSubscriptionStatus,
    clearFetchingStatus,
  } = discountContext;

  const router = useRouter();
  const [browser, setBrowser] = useState("");
  const fetchedProductPerSce = 30; // how many products can be fetched in seconds.
  const [secondNeeded, setSecondNeeded] = useState(900); //how much second might be needed more
  const [filterStatus, setFilterStatus] = useState("");
  const [fetchingSubscriptionStatus, setFetchingSubscriptionStatus] =
    useState(false);
  const [filterStatusCurrentCount, setFilterStatusCurrentCount] = useState(0);
  const [filterStatusTotalCount, setFilterStatusTotalCount] = useState(0);
  const [productsFetchingModal, setProductsFetchingModal] = useState(false);
  const [productFetchInterval, setProductFetchInterval] = useState(null);
  const [activeFilteringStatusModal, setAtiveFilteringStatusModal] =
    useState(false);
  const [filteringStatusFinishingUp, setFilteringStatusFinishingUp] =
    useState(false);
  const [filteringStatusCompleted1, setFilteringStatusCompleted1] =
    useState(false);
  const [filteringStatusCompleted2, setFilteringStatusCompleted2] =
    useState(false);

  const [anayltics, setAnalytics] = useState(null);

  const handleFilteringStatusModal = useCallback(
    () => setAtiveFilteringStatusModal(!activeFilteringStatusModal),
    [activeFilteringStatusModal]
  );

  const fetchAnalytics = () => {
    authenticatedFetch({path: `/api/getManiAnalytics/${shop}?shop=${shop1}`, method: "GET", host, jwtToken,shop1})
      .then((res) => {
        console.log("Data", res)
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getManiAnalytics");
        }
      })
      .then((data) => {
        setAnalytics(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchAnalytics();
    getProductsFetchingStatus();
    setFetchingSubscriptionStatus(true);
    getSubscriptionStatus((err, data) => {
      if (err) {
        snackbar("Failure! Unable to get subscription Status", "error");
        setFetchingSubscriptionStatus(false);
      } else {
        if (data.subscriptionConfirmed === true) {
          snackbar(
            "Congratulations! You have Successfully Switched To Pro Plan",
            "success"
          );
        } else if (data.subscriptionConfirmed === false) {
          snackbar("Failed to switch to Pro Plan. Please Try Again", "error");
        }
        // snackbar("Success! Unable to get subscription Status","success");
        setFetchingSubscriptionStatus(false);
      }
    });
  }, []);

  const handleBulkPriceChange = () => {
    // if (
    //   gettingFilterStatusFromRes[gettingFilterStatusFromRes.length - 1] === "F"
    // ) {
    //   handleFilteringStatusModal();
    // } else {
    router.push("/BulkPriceChange");
    // }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (
        productsFetchingStatusCurrentCount == filterStatusTotalCount &&
        productsFetchingStatusCurrentCount != 0
      ) {
        setFilteringStatusFinishingUp(true);

        if (filteringStatusCompleted1) {
          setFilteringStatusCompleted2(true);
        }
      }

      if (
        filterStatusTotalCount - productsFetchingStatusCurrentCount <=
        fetchedProductPerSce
      ) {
        setFilterStatusCurrentCount(filterStatusTotalCount);
      } else {
        setFilterStatusCurrentCount(
          productsFetchingStatusCurrentCount + fetchedProductPerSce
        );
      }
      setSecondNeeded(secondNeeded - 1);
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [secondNeeded]);

  useEffect(() => {
    let fetchInterval_ = null;
    // if(filterStatus!="completed"){
    fetchInterval_ = setInterval(() => {
      getProductsFetchingStatus();
    }, 2000);
    setProductFetchInterval(fetchInterval_);
    // }

    return () => {
      if (fetchInterval_) clearInterval(fetchInterval_);
      if (productFetchInterval) setProductFetchInterval(null);
      clearFetchingStatus();
    };
  }, []);

  useEffect(() => {
    setFilterStatus(productsFetchingStatus);
  }, [productsFetchingStatus]);

  useEffect(() => {
    setFilterStatusTotalCount(productsFetchingStatusTotalCount);
    setSecondNeeded(
      Math.ceil(Math.abs(productsFetchingStatusTotalCount-productsFetchingStatusCurrentCount) / fetchedProductPerSce)
    );
  }, [productsFetchingStatusTotalCount]);

  useEffect(() => {
    if (filterStatus == "inprogress") {
      setProductsFetchingModal(true);
    }
  }, [filterStatus == "inprogress"]);

  useEffect(() => {
    // setTimeout(() => {
    if (filterStatus == "completed") {
      setFilteringStatusCompleted1(true);
      if (productFetchInterval) {
        clearInterval(productFetchInterval);
        setProductFetchInterval(null);
      }
      if (
        productsFetchingStatusCurrentCount == filterStatusTotalCount &&
        filterStatusTotalCount == 0
      ) {
        setFilteringStatusFinishingUp(true);
        setFilteringStatusCompleted2(true);
      }
    }
    // }, 1000);
  }, [filterStatus == "completed"]);
  const loader = (
    <div className="text-center center-in-div">
      <div
        className="spinner-border text-primary text-spinner-blue mb-3"
        style={{ width: "4rem", height: "4rem" }}
        role="status"
      >
        <span className="sr-only">Loading</span>
      </div>
      <div>
        <h2>Loading</h2>
      </div>
    </div>
  );
  // return filterStatus == "" || fetchingSubscriptionStatus ? (
  //   <Wrapper tab={0}>
  //     <div className="main bg-img vh100">{loader}</div>
  //   </Wrapper>
  // ) :
  return (
    <Wrapper tab={0}>
      <div className="main bg-img">
        {/* Title Section */}

        {anayltics ? (
          <div className="homepage-container mt-5 mb-5">
            <div
              className="homepage-card homepage-container-margin panel-shadow-border col"
              style={{
                height: "95px",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <h2 className="title">
                <strong>{anayltics.productPriceChanged}</strong>
              </h2>
              <h3 className="text-muted">Variants Prices Changed</h3>
            </div>
            <div
              className="homepage-card homepage-container-margin  panel-shadow-border col"
              style={{
                height: "95px",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <h2 className="title">
                <strong>{anayltics.productPutOnSale}</strong>
              </h2>
              <h3 className="text-muted">Variants Put On Sale</h3>
            </div>
            <div
              className="homepage-card  panel-shadow-border col"
              style={{
                height: "95px",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <h2 className="title">
                <strong>{anayltics.totalRevenue}</strong>
              </h2>
              <h3 className="text-muted">Total Revenue Generated</h3>
            </div>
          </div>
        ) : (
          <AnalticsSkelton />
        )}

        <div className="title-section d-flex flex-row justify-content-between">
          <div className="sub-headings">Pick an option to get started</div>
        </div>

        <hr className="hr-title" />
        {/* {filterStatus == "" || fetchingSubscriptionStatus ? (
          <PickOptionSkelton />
        ) :  */}
        {/* ( */}
        <div className="homepage-container mt-5 pb-5">
          <div className="homepage-card bottom-container homepage-container-margin panel-shadow-border">
            <div className="homepage-card-left">
              <img className="homepage-icon" src="/img/homepage-1.png" />
            </div>
            <div className="homepage-card-right">
              <div className="homepage-card-title">Bulk Price Change</div>
              <div className="homepage-card-text">
                Schedule product prices in bulk, directly on shopify without the
                need to depend on discounts codes
              </div>
              {filterStatus == "" || fetchingSubscriptionStatus ? (
                <DefaultSkelton />
              ) : (
                <div
                  className="homepage-card-btn"
                  onClick={handleBulkPriceChange}
                >
                  Get Started
                </div>
              )}
            </div>
          </div>
          <div className="homepage-card bottom-container panel-shadow-border">
            <div className="homepage-card-left">
              <img className="homepage-icon" src="/img/homepage-2.png" />
            </div>
            <div className="homepage-card-right">
              <div className="homepage-card-title">Create A Sale</div>
              <div className="homepage-card-text">
                Create a sale that will be offered to everyone and all products
                in your store
              </div>
              {filterStatus == "" || fetchingSubscriptionStatus ? (
                <DefaultSkelton />
              ) : (
                <Link href="/CreateASale">
                  <div className="homepage-card-btn">Get Started</div>
                </Link>
              )}
            </div>
          </div>
        </div>
        {/* )} */}
      </div>

      <ProductFetchModal
        productsFetchingModal={productsFetchingModal}
        filterStatusCurrentCount={filterStatusCurrentCount}
        filterStatusTotalCount={filterStatusTotalCount}
        productsFetchingStatusCurrentCount={productsFetchingStatusCurrentCount}
        filteringStatusFinishingUp={filteringStatusFinishingUp}
        filteringStatusCompleted2={filteringStatusCompleted2}
        setProductsFetchingModal={setProductsFetchingModal}
        secondNeeded={secondNeeded}
        filterStatus={filterStatus}
      />

      <Modal
        open={activeFilteringStatusModal}
        onClose={handleFilteringStatusModal}
      >
        <div className="save-btn-modal-container">
          <div className="svg-modal">
            <div
              className="spinner-border text-primary"
              style={{ width: "8rem", height: "8rem" }}
              role="status"
            />
          </div>

          <div className="modal-heading">Product Filtering in Progress</div>

          <div className="modal-text">
            Please wait while Product Filtering is in Progress.
          </div>

          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn cancel-modal-btn"
                onClick={handleFilteringStatusModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default NewDiscount;
